import { getFormProps, useForm } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";

import { useFetcher } from "@remix-run/react";
import type { FormEvent } from "react";
import { useState } from "react";
import { z } from "zod";
import { Button } from "~/components/ui/buttons";
import { Input } from "~/components/ui/forms/inputs";

import { type action } from "../login";
import { GoogleAuth } from "./googleAuth";
import useGoogleAuth from "./googleAuth/useGoogleAuth";

export const signInSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .trim()
    .toLowerCase()
    .email("Email is invalid")
    .min(1),
  redirectString: z.string().optional(),
  password: z.string().optional(),
});

export type SignInDTO = z.infer<typeof signInSchema>;

export class SignInError extends Error {
  public errors: any;
  constructor(message: string, errors: any) {
    super(message);
    this.name = "SignInError";
    this.errors = errors;
  }
}

export const SignInForm = ({
  onSubmit,
}: {
  onSubmit?: (e: FormEvent<HTMLFormElement>, data: any) => Promise<void>;
}) => {
  const fetcher = useFetcher<typeof action>({ key: "sign-in" });
  const [errors, setErrors] = useState<any>({});
  const { googleSignIn } = useGoogleAuth();
  const [form, fields] = useForm({
    lastResult: fetcher.data as any,
    shouldValidate: "onBlur",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: signInSchema });
    },
    async onSubmit(e, data) {
      /**
       * Allow to define a custom on submit function when more granular errors
       * need to be handled. `errors` is an object that matches Zod error map,
       * and then all errors are passed down to their inputs.
       */
      try {
        if (onSubmit) {
          await onSubmit(e, data);
        }
      } catch (err: any) {
        setErrors(err.errors);
      }
    },
  });

  const isLoading = fetcher.state === "submitting";

  return (
    <fetcher.Form method="post" {...getFormProps(form)}>
      <div className="mb-4 w-full md:m-auto md:w-3/4">
        <div className="flex justify-center">
          <GoogleAuth handleSignIn={googleSignIn} signUp={false} />
        </div>
        <div className="mt-8 flex w-full items-center">
          <div className="w-1/2 border-b border-gray-300" />
          <span className="px-4 italic text-gray-600">Or...</span>
          <div className="w-1/2 border-b border-gray-300" />
        </div>
      </div>
      <div className="m-auto mt-8 w-full rounded-md border border-gray-300 bg-white p-[12px] md:w-3/4">
        <Input
          id={fields.email.name}
          name={fields.email.name}
          defaultValue={fields.email.value}
          placeholder="john.doe@withhansa.com"
          type="email"
          autoComplete="email"
          label="Email address"
          required
          aria-invalid={
            [...(fields.email.errors || []), ...(errors.email || [])].filter(
              Boolean,
            ).length > 0
          }
          error={[...(fields.email.errors || []), ...(errors.email || [])]
            .filter(Boolean)
            .join(", ")}
        />
        <input
          hidden
          id={fields.redirectString.name}
          name={fields.redirectString.name}
          defaultValue={fields.redirectString.value}
          aria-hidden
        />
      </div>
      <div className="m-auto flex flex-wrap justify-center md:w-3/4">
        <Button
          className="mt-4 w-full"
          type="submit"
          isLoading={isLoading}
          disabled={!form.valid || isLoading}
          variant="primary"
        >
          Email me a login link
        </Button>
      </div>
    </fetcher.Form>
  );
};
